import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Partners } from "./components/partners";
import { About } from "./components/about";
import { Services } from "./components/services";
import Home from "./components/home";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { News } from "./components/news";
import { News1 } from "./components/news1";
import { News2 } from "./components/news2";
import { News3 } from "./components/news3";
import { News4 } from "./components/news4";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

const App = () => {
  return (
    <>
      <Router>
        <Navigation />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news1" element={<News1 />} />
            <Route path="/news2" element={<News2 />} />
            <Route path="/news3" element={<News3 />} />
            <Route path="/news4" element={<News4 />} />
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;
