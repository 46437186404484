import { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { Partners } from "./partners";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { News } from "./news";
import { News1 } from "./news1";
import { News2 } from "./news2";
import { News3 } from "./news3";
import { News4 } from "./news4";
import { Team } from "./Team";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      {/* <Navigation /> */}
      <Header data={landingPageData.Header} />
      {/* <Features data={landingPageData.Features} /> */}
      <Partners data={landingPageData.Partners} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      {/* <Gallery data={landingPageData.Gallery}/>
      <Testimonials data={landingPageData.Testimonials} /> */}
      <News data={landingPageData.News} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Home;
